<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    editRecord () {
      this.params.clicked(this.params.data)
    },

    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: `Tem certeza que deseja excluir a conta \n "${this.params.data.account}"?`,
        accept: this.deleteRecord,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    deleteRecord () {
      this.$store.dispatch('account/delete', this.params.data.id)
        .then(()   => {
          // this.params.setDatasource();// assim chamo a função vinda de fora
          this.params.api.refreshInfiniteCache()
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'SUCESSO',
        text: 'Dados excluídos com sucesso',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    }
  }
}
</script>


export default {
  //MANIPULA A ÚNICA CONTA OBJETO FORA DO ARRAY DE CONTAS NO ESTADO
  SET (state, payload) {
    state.bank = Object.assign({}, state.bank, payload) //precisa ser assim para manter a reatividade
  },

  //SUBSTITUI O ARRAY DE CONTAS POR UM NOVO
  REFRESH (state, payload) {
    state.banks = payload
  }
}

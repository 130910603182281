var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data
    ? _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _c("feather-icon", {
            staticClass: "mt-4",
            attrs: {
              icon: "CircleIcon",
              svgClasses: ["fill-current h-4 w-4", _vm.color],
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import state from './moduleBankState.js'
import mutations from './moduleBankMutations.js'
import actions from './moduleBankActions.js'
import getters from './moduleBankGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}


<template>

  <div id="page-user-list">

    <popup-pdf ref="popupPdf" title="Saldo de caixas e contas" />
    <add-account-modal :show="popupAddAccount" @saved="refreshGrid" @cancel="popupAddAccount = false" />
    <edit-account-modal :show="popupEditAccount" :account="account" @saved="refreshGrid" @cancel="popupEditAccount = false" />

    <!-- TRANSFERÊNCIAS -->
    <vs-popup class="popup40" :fullscreen="false" title="Transferênca" :active.sync="popupTransfer">
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="vs-input--label">De</label>
          <v-select class="w-full" v-model="transfer.account_from" :reduce="option => option.value" :clearable="false"
            data-vv-as="De" data-vv-scope="transfer" v-validate.initial="'required'" name="account_from"
            :options="accounts" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="vs-input--label">Para</label>
          <v-select class="w-full" v-model="transfer.account_to" :reduce="option => option.value" :clearable="false"
            data-vv-as="Para" data-vv-scope="transfer" v-validate.initial="'required'" name="account_to"
            :options="accounts" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="vs-input--label">Valor</label>
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="transfer.value"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
      </div>

      <vs-divider />
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupTransfer = false">Cancelar</vs-button>
            <vs-button class="ml-4" color="danger" @click="transferDo">Transferir</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

    <vx-card v-if="!showAccounts" class="general-cash-filters mb-4">
      <vs-alert class="mt-2" color="primary" title="Orientações:" :active="!showAccounts" icon-pack="feather">
        <p>Você não tem permissão para acessar as Contas.</p>
        <p>Peça para seu administrador alterar as configurações e liberar seu acesso caso necessário.</p>
      </vs-alert>
    </vx-card>

    <div v-else class="vx-card p-6">

      <!-- SE A EMPRESA USA CX INDIVIDUAL -->
      <div v-if="user.company.configuration && user.company.configuration.financial && user.company.configuration.financial.cashier" class="my-cashier-filters mb-4">
        <h5>Posição atual dos Caixas Individuais</h5>

        <vs-table noDataText="Nenhum Caixa Individual foi utilizado até este momento." :data="cashiers" hoverFlat stripe style="overflow: -webkit-paged-y">
          <template slot="thead">
            <vs-th>Responsável</vs-th>
            <vs-th>Status</vs-th>
            <vs-th>Abertura</vs-th>
            <vs-th>Saldo Inicial</vs-th>
            <vs-th>Fechamento</vs-th>
            <vs-th>Saldo Atual</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="i" v-for="(cashier, i) in data" style="opacity: 1 !important">
              <vs-td><span>{{ cashier.name }}</span></vs-td>
              <vs-td><span> {{ cashier.last_cashier.status === 'OPEN' ? 'ABERTO' : 'FECHADO' }} </span></vs-td>
              <vs-td><span> {{ cashier.last_cashier.opening_date | dateTime('dateTime') }} </span></vs-td>
              <vs-td>{{ (cashier.last_cashier.opening_balance || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</vs-td>
              <vs-td>{{ cashier.last_cashier.closed_date | dateTime('dateTime') }}</vs-td>
              <vs-td>{{ (cashier.last_cashier.final_balance || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</vs-td>
          </vs-tr>
          </template>
        </vs-table>
        <vs-divider></vs-divider>
      </div>

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ accountsData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : accountsData.total }} de {{ accountsData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown> -->
        </div>

        <!-- TRANSFERÊNCIA -->
        <vs-button @click="handleTransfer"
        class="m-icon sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
        type="line" color="primary" icon="compare_arrows">Transferência</vs-button>

        <!-- NOVA CONTA -->
        <vs-button @click="addAccount"
        class="shadow-drop sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
        icon-pack="feather"
        icon="icon-plus-circle">Nova Conta</vs-button>
           <vs-button @click="printPage"
       class="m-icon sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
        icon-pack="feather"
        color="primary"
        type="line"
        icon="icon-printer" />
      </div>

      <vs-divider></vs-divider>

      <h5>CONTAS EXTERNAS</h5> <small class="mb-2"><strong>Alteram</strong> saldo no cx geral.</small>
      <vs-table noDataText="Nenhuma Conta Externa Cadastrada." :data="externalAccountsData.data" hoverFlat stripe style="overflow: -webkit-paged-y">
        <template slot="thead">
          <vs-th>Conta</vs-th>
          <vs-th>Tipo</vs-th>
          <vs-th>Saldo</vs-th>
          <vs-th>Ações</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="i" v-for="(externalAccount, i) in data" style="opacity: 1 !important">
            <vs-td><span>{{ externalAccount.account }}</span></vs-td>
            <vs-td><span> <feather-icon icon="CircleIcon" class="mt-4" :svgClasses="['fill-current h-4 w-4',
              (externalAccount.bank_account && !externalAccount.config_boleto) ? 'text-primary' : (externalAccount.config_boleto ? 'text-warning' : 'text-success')
            ]" /> </span></vs-td>
            <vs-td>{{ (externalAccount.balance || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</vs-td>
            <vs-td>
              <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editAccount(externalAccount)" />
                <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(externalAccount)" />
              </div>
            </vs-td>
        </vs-tr>
        </template>
      </vs-table>
      <vs-divider></vs-divider>

      <h5>CONTAS INTERNAS</h5> <small><strong>Não Alteram</strong> saldo no cx geral.</small>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="20"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />
    </div>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import Printd from 'printd'
import PopupPdf from '@/components/report/pdf/PopupPdf'

import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'
// Cell Renderer
import CellRendererAccount from './cell-renderer/CellRendererAccount.vue'
import CellRendererType from './cell-renderer/CellRendererType.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

const addAccountModal = () => import(/* webpackChunkName: "addAccountModal" */ './modals/AddAccount.vue')
const editAccountModal = () => import(/* webpackChunkName: "editAccountModal" */ './modals/EditAccount.vue')
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import { CurrencyInput } from 'vue-currency-input'

// Store Module
import moduleAccount from '@/store/account/moduleAccount.js'
import moduleBank from '@/store/bank/moduleBank.js'
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import moduleMyCashier from '@/store/my-cashier/moduleMyCashier.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererAccount,
    CellRendererType,
    CellRendererActions,
    addAccountModal,
    editAccountModal,
    vSelect,
    CurrencyInput,
    PopupPdf
  },
  data () {
    return {
      showAccounts: true,
      localeText: null,
      popupTransfer: false,
      accountsFromOptions: [],
      accountsToOptions: [],

      transfer: {
        account_from: null,
        account_to: null,
        value: 0
      },
      accountId: null, // SOMENTE PARA EXCLUSÃO DE CONTA EXTERNA
      account: {
        id: null,
        name: null,
        type: 'simple',
        type_payment: null,
        account_plan: null,
        balance: 0,
        default_interest_type: '%',
        default_interest: 0,
        default_interest_days: 1,
        default_fee_type: '%',
        default_fee: 0,
        default_fee_days: 1,
        external: false,
        cashier: null,
        cardAccount: {
          default_credit_tax: 0,          // valor da taxa (custo) por lançamento default(0)
          default_credit_tax_type: '%',   // tipo de taxa ($ ou %) default('%')
          default_debit_tax: 0,           // valor da taxa (custo) por lançamento default(0)
          default_debit_tax_type: '%',    // tipo de taxa ($ ou %) default('%')
          type_day_receipt_credit: 'FIXED', // FIXED (recebe sempre no mesmo dia do mês) DINAMIC (RECEBE X DIAs após o pagamento) default('FIXED')
          day_receipt_credit: 1, // default(1)
          type_day_receipt_debit: 'FIXED',   // FIXED (recebe sempre no mesmo dia do mês) DINAMIC (RECEBE X DIAs após o pagamento) default('FIXED')
          day_receipt_debit: 1   //  default(1)
        },
        bankAccount: {
          bank: null,
          boleto: false,
          bankBranche: {
            code: null,
            dv: null
          },
          currentAccount: {
            code: null,
            dv: null
          },
          configBoleto: {
            especie_documento: null,
            convenio: null,
            carteira: null,
            printing_type: 'normal',
            posto: null,
            byte: null,
            next_number: 1,
            remessa_next_number: 1,
            cnab: 240,
            default_interest_type: '%',
            default_interest: 0,
            default_interest_days: 1,
            default_fee_type: '%',
            default_fee: 0,
            default_fee_days: 1,
            payment_place: null,
            demonstrative_descriptions: [
              { demonstrative_description: null},
              { demonstrative_description: null},
              { demonstrative_description: null}
            ],
            instructions: [
              { instruction: null},
              { instruction: null},
              { instruction: null}
            ],
            tax: 0,
            tax_apply: 'on_register' // on_register on_payment
          }
        }
      },
      // AgGrid
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Conta',
          field: 'account',
          width: 350,
          floatingFilter:true,
          filter: 'agTextColumnFilter',
          filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith'],
            defaultOption: 'startsWith',
            debounceMs: 800
          },
          cellRendererFramework: 'CellRendererAccount'
        },
        {
          headerName: 'Tipo',
          field: 'type',
          width: 200,
          cellRendererFramework: 'CellRendererType'
        },
        {
          headerName: 'Saldo',
          field: 'balance',
          valueFormatter: params => { return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' },
          width: 150
        },
        {
          headerName: 'Ações',
          field: 'transactions',
          width: 95,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            clicked: (data) => {
              this.editAccount(data)
            }
          }
        }
      ],

      components: {
        CellRendererAccount,
        CellRendererType,
        CellRendererActions
      },

      payload: {
        offset: 0,
        limit: 40,
        params: null
      },
      popupAddAccount: false,
      popupEditAccount: false
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    cashiers () {
      return this.$store.state.myCashier.usersLastCashiers
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    accountsData () {
      return this.$store.state.account.accounts
    },
    externalAccountsData () {
      return this.$store.state.account.externalAccounts
    },
  },
  methods: {
    printPage () {
      this.$refs.popupPdf.get(`${process.env.VUE_APP_API_URL}/report/financial/cashAndAccountBalance`)
    },
    async handleTransfer () {
      this.transfer.value = 0
      this.transfer.account_to = null
      this.transfer.account_from = null
      this.popupTransfer = true
    },
    async transferDo () {
      try {
        this.$vs.loading()
        const payload = {
          value: this.transfer.value,
          account_to: this.transfer.account_to,
          account_from: this.transfer.account_from
        }
        await this.$http.post(`${process.env.VUE_APP_API_URL}/account/transfer`, payload)
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Valor transferido.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.refreshGrid()
        this.popupTransfer = false
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    onGridReady () {
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params
          await self.getAccounts() //AQUI EU TENHO O PAYLOAD E POSSO FAZER A CONSULTA, AO MESMO TEMPO DEVO AGUARDARA RESPOSTA PARA CONTINUAR
          let lastRow = -1
          if (self.accountsData.total <= params.endRow) {
            lastRow = self.accountsData.total
          }
          params.successCallback(self.accountsData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAccounts () {
      this.gridApi.showLoadingOverlay()
      const accounts =  await this.$store.dispatch('account/fetchFilter', this.payload).catch(err => { console.error(err) })
      this.gridApi.hideOverlay()
      if (!accounts.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return accounts
    },

    refreshGrid () {
      this.gridApi.setDatasource(this.dataSource)
    },

    addAccount () {
      this.popupAddAccount = true
    },
    editAccount (account) {
      this.account.id = account.id
      this.account.name = account.account
      this.account.type = account.card_account ? 'cardAccount' : (account.bank_account ? 'bankAccount' : 'simple')
      this.account.type_payment = account.type_payments ? account.type_payments.map(a => a.id) : null
      this.account.account_plan = account.account_plans ? account.account_plans.map(a => a.id) : null
      this.account.cashier =  account.cashier

      this.account.balance = account.balance
      this.account.default_interest_type = account.default_interest_type
      this.account.default_interest = account.default_interest
      this.account.default_interest_days = account.default_interest_days
      this.account.default_fee_type = account.default_fee_type
      this.account.default_fee = account.default_fee
      this.account.default_fee_days = account.default_fee_days
      this.account.external = account.external

      if (account.card_account) {
        this.account.cardAccount.default_credit_tax = account.card_account.default_credit_tax ? account.card_account.default_credit_tax : 0
        this.account.cardAccount.default_credit_tax_type = account.card_account.default_credit_tax_type ? account.card_account.default_credit_tax_type : '%'
        this.account.cardAccount.default_debit_tax = account.card_account.default_debit_tax ? account.card_account.default_debit_tax : 0
        this.account.cardAccount.default_debit_tax_type = account.card_account.default_debit_tax_type ? account.card_account.default_debit_tax_type : '%'
        this.account.cardAccount.type_day_receipt_credit = account.card_account.type_day_receipt_credit ? account.card_account.type_day_receipt_credit : 'FIXED'
        this.account.cardAccount.day_receipt_credit = account.card_account.day_receipt_credit ? account.card_account.day_receipt_credit : 1
        this.account.cardAccount.type_day_receipt_debit = account.card_account.type_day_receipt_debit ? account.card_account.type_day_receipt_debit : 'FIXED'
        this.account.cardAccount.day_receipt_debit = account.card_account.day_receipt_debit ? account.card_account.day_receipt_debit : 1
      }

      this.account.bankAccount.bank = account.bank_account ? account.bank_account.bank_branche.bank_id : null
      this.account.bankAccount.boleto = !!(account.account && account.config_boleto)
      this.account.bankAccount.bankBranche.code = account.bank_account ? account.bank_account.bank_branche.code : null
      this.account.bankAccount.bankBranche.dv = account.bank_account ? account.bank_account.bank_branche.dv : null
      this.account.bankAccount.currentAccount.code = account.bank_account ? account.bank_account.code : null
      this.account.bankAccount.currentAccount.dv = account.bank_account ? account.bank_account.dv : null

      this.account.bankAccount.configBoleto.especie_documento = account.config_boleto ? account.config_boleto.especie_documento : null
      this.account.bankAccount.configBoleto.convenio = account.config_boleto ? account.config_boleto.convenio : null
      this.account.bankAccount.configBoleto.carteira = account.config_boleto ? account.config_boleto.carteira : null
      this.account.bankAccount.configBoleto.variacao_carteira = account.config_boleto ? account.config_boleto.variacao_carteira : null
      this.account.bankAccount.configBoleto.printing_type = account.config_boleto ? account.config_boleto.printing_type : 'normal'
      this.account.bankAccount.configBoleto.posto = account.config_boleto ? account.config_boleto.posto : null
      this.account.bankAccount.configBoleto.byte = account.config_boleto ? account.config_boleto.byte : null
      this.account.bankAccount.configBoleto.next_number = account.config_boleto ? (account.config_boleto.boleto_number_control ? account.config_boleto.boleto_number_control.last_number + 1 : 1) : 1
      this.account.bankAccount.configBoleto.remessa_next_number = account.config_boleto ? (account.config_boleto.remessa_number_control ? account.config_boleto.remessa_number_control.last_number + 1 : 1) : 1
      this.account.bankAccount.configBoleto.cnab = account.config_boleto ? account.config_boleto.cnab : 240

      this.account.bankAccount.configBoleto.default_interest_type = account.config_boleto ? account.config_boleto.default_interest_type : '%'
      this.account.bankAccount.configBoleto.default_interest = account.config_boleto ? account.config_boleto.default_interest : 0
      this.account.bankAccount.configBoleto.default_interest_days = account.config_boleto ? account.config_boleto.default_interest_days : 1
      this.account.bankAccount.configBoleto.default_fee_type = account.config_boleto ? account.config_boleto.default_fee_type : '%'
      this.account.bankAccount.configBoleto.default_fee = account.config_boleto ? account.config_boleto.default_fee : 0
      this.account.bankAccount.configBoleto.default_fee_days = account.config_boleto ? account.config_boleto.default_fee_days : 1
      this.account.bankAccount.configBoleto.payment_place = account.config_boleto ? account.config_boleto.payment_place : null
      this.account.bankAccount.configBoleto.tax = account.config_boleto ? account.config_boleto.tax : 0
      this.account.bankAccount.configBoleto.tax_apply = account.config_boleto ? account.config_boleto.tax_apply : 'on_register'
      if (account.config_boleto) {
        const demonstrative_descriptions = JSON.parse(account.config_boleto.demonstrative_description).reduce((ac, cV) => {
          ac.push({ demonstrative_description: cV })
          return ac
        }, [])
        this.account.bankAccount.configBoleto.demonstrative_descriptions = demonstrative_descriptions

        const instructions = JSON.parse(account.config_boleto.instructions).reduce((ac, cV) => {
          ac.push({ instruction: cV })
          return ac
        }, [])
        this.account.bankAccount.configBoleto.instructions = instructions

      } else {
        this.account.bankAccount.configBoleto.demonstrative_descriptions = [
          { demonstrative_description: null},
          { demonstrative_description: null},
          { demonstrative_description: null}
        ]
        this.account.bankAccount.configBoleto.instructions = [
          { instruction: null},
          { instruction: null},
          { instruction: null}
        ]
      }
      this.popupEditAccount = true
    },

    // CONTAS EXTERNAS SAO SEPARADAS
    confirmDeleteRecord (account) {
      this.accountId = account.id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: `Tem certeza que deseja excluir a conta \n "${account.account}"?`,
        accept: this.deleteRecord,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    deleteRecord () {
      this.$store.dispatch('account/delete', this.accountId)
        .then(()   => {
          // this.params.setDatasource();// assim chamo a função vinda de fora
          this.refreshGrid()
          this.$vs.notify({
            color: 'success',
            title: 'SUCESSO',
            text: 'Dados excluídos com sucesso',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(err => { console.error(err) })
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('accountsData')
    if (!moduleBank.isRegistered) {
      this.$store.registerModule('bank', moduleBank)
      moduleBank.isRegistered = true
    }
    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    if (!moduleAccount.isRegistered) {
      // this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    this.$store.dispatch('account/fetchAll').then(() => {}).catch(err => { console.error(err) })

    if (!moduleMyCashier.isRegistered) {
      this.$store.registerModule('myCashier', moduleMyCashier)
      moduleMyCashier.isRegistered = true
    }
    this.$store.dispatch('myCashier/fetchUsersLastCashiers').then(() => {}).catch(err => { console.error(err) })

    // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
    const mod = this.user.permissions_user.find(a => a.module === 'spa_account')
    if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('read'))) {
      this.showAccounts = false
    }
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.m-icon .material-icons {
  font-size: 19px !important;
}

.popup40 .vs-popup {
  width: 40% !important;
}

</style>

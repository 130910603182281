<template>
  <div v-if="params.data" class="flex items-center">
    {{ params.value }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink'
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-user-list" } },
    [
      _c("popup-pdf", {
        ref: "popupPdf",
        attrs: { title: "Saldo de caixas e contas" },
      }),
      _c("add-account-modal", {
        attrs: { show: _vm.popupAddAccount },
        on: {
          saved: _vm.refreshGrid,
          cancel: function ($event) {
            _vm.popupAddAccount = false
          },
        },
      }),
      _c("edit-account-modal", {
        attrs: { show: _vm.popupEditAccount, account: _vm.account },
        on: {
          saved: _vm.refreshGrid,
          cancel: function ($event) {
            _vm.popupEditAccount = false
          },
        },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "popup40",
          attrs: {
            fullscreen: false,
            title: "Transferênca",
            active: _vm.popupTransfer,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupTransfer = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [_vm._v("De")]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "De",
                    "data-vv-scope": "transfer",
                    name: "account_from",
                    options: _vm.accounts,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.transfer.account_from,
                    callback: function ($$v) {
                      _vm.$set(_vm.transfer, "account_from", $$v)
                    },
                    expression: "transfer.account_from",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Para"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Para",
                    "data-vv-scope": "transfer",
                    name: "account_to",
                    options: _vm.accounts,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.transfer.account_to,
                    callback: function ($$v) {
                      _vm.$set(_vm.transfer, "account_to", $$v)
                    },
                    expression: "transfer.account_to",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Valor"),
                ]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  model: {
                    value: _vm.transfer.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.transfer, "value", $$v)
                    },
                    expression: "transfer.value",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupTransfer = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { color: "danger" },
                      on: { click: _vm.transferDo },
                    },
                    [_vm._v("Transferir")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      !_vm.showAccounts
        ? _c(
            "vx-card",
            { staticClass: "general-cash-filters mb-4" },
            [
              _c(
                "vs-alert",
                {
                  staticClass: "mt-2",
                  attrs: {
                    color: "primary",
                    title: "Orientações:",
                    active: !_vm.showAccounts,
                    "icon-pack": "feather",
                  },
                },
                [
                  _c("p", [
                    _vm._v("Você não tem permissão para acessar as Contas."),
                  ]),
                  _c("p", [
                    _vm._v(
                      "Peça para seu administrador alterar as configurações e liberar seu acesso caso necessário."
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "vx-card p-6" },
            [
              _vm.user.company.configuration &&
              _vm.user.company.configuration.financial &&
              _vm.user.company.configuration.financial.cashier
                ? _c(
                    "div",
                    { staticClass: "my-cashier-filters mb-4" },
                    [
                      _c("h5", [
                        _vm._v("Posição atual dos Caixas Individuais"),
                      ]),
                      _c(
                        "vs-table",
                        {
                          staticStyle: { overflow: "-webkit-paged-y" },
                          attrs: {
                            noDataText:
                              "Nenhum Caixa Individual foi utilizado até este momento.",
                            data: _vm.cashiers,
                            hoverFlat: "",
                            stripe: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ data }) {
                                  return _vm._l(data, function (cashier, i) {
                                    return _c(
                                      "vs-tr",
                                      {
                                        key: i,
                                        staticStyle: {
                                          opacity: "1 !important",
                                        },
                                      },
                                      [
                                        _c("vs-td", [
                                          _c("span", [
                                            _vm._v(_vm._s(cashier.name)),
                                          ]),
                                        ]),
                                        _c("vs-td", [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  cashier.last_cashier
                                                    .status === "OPEN"
                                                    ? "ABERTO"
                                                    : "FECHADO"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                        _c("vs-td", [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("dateTime")(
                                                    cashier.last_cashier
                                                      .opening_date,
                                                    "dateTime"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              (
                                                cashier.last_cashier
                                                  .opening_balance || 0
                                              ).toLocaleString("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                              })
                                            )
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateTime")(
                                                cashier.last_cashier
                                                  .closed_date,
                                                "dateTime"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(
                                              (
                                                cashier.last_cashier
                                                  .final_balance || 0
                                              ).toLocaleString("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                              })
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  })
                                },
                              },
                            ],
                            null,
                            false,
                            4132363015
                          ),
                        },
                        [
                          _c(
                            "template",
                            { slot: "thead" },
                            [
                              _c("vs-th", [_vm._v("Responsável")]),
                              _c("vs-th", [_vm._v("Status")]),
                              _c("vs-th", [_vm._v("Abertura")]),
                              _c("vs-th", [_vm._v("Saldo Inicial")]),
                              _c("vs-th", [_vm._v("Fechamento")]),
                              _c("vs-th", [_vm._v("Saldo Atual")]),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c("vs-divider"),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center" },
                [
                  _c("div", { staticClass: "flex-grow" }),
                  _c(
                    "vs-button",
                    {
                      staticClass:
                        "m-icon sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4",
                      attrs: {
                        type: "line",
                        color: "primary",
                        icon: "compare_arrows",
                      },
                      on: { click: _vm.handleTransfer },
                    },
                    [_vm._v("Transferência")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass:
                        "shadow-drop sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-plus-circle",
                      },
                      on: { click: _vm.addAccount },
                    },
                    [_vm._v("Nova Conta")]
                  ),
                  _c("vs-button", {
                    staticClass:
                      "m-icon sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4",
                    attrs: {
                      "icon-pack": "feather",
                      color: "primary",
                      type: "line",
                      icon: "icon-printer",
                    },
                    on: { click: _vm.printPage },
                  }),
                ],
                1
              ),
              _c("vs-divider"),
              _c("h5", [_vm._v("CONTAS EXTERNAS")]),
              _c("small", { staticClass: "mb-2" }, [
                _c("strong", [_vm._v("Alteram")]),
                _vm._v(" saldo no cx geral."),
              ]),
              _c(
                "vs-table",
                {
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: {
                    noDataText: "Nenhuma Conta Externa Cadastrada.",
                    data: _vm.externalAccountsData.data,
                    hoverFlat: "",
                    stripe: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (externalAccount, i) {
                          return _c(
                            "vs-tr",
                            {
                              key: i,
                              staticStyle: { opacity: "1 !important" },
                            },
                            [
                              _c("vs-td", [
                                _c("span", [
                                  _vm._v(_vm._s(externalAccount.account)),
                                ]),
                              ]),
                              _c("vs-td", [
                                _c(
                                  "span",
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mt-4",
                                      attrs: {
                                        icon: "CircleIcon",
                                        svgClasses: [
                                          "fill-current h-4 w-4",
                                          externalAccount.bank_account &&
                                          !externalAccount.config_boleto
                                            ? "text-primary"
                                            : externalAccount.config_boleto
                                            ? "text-warning"
                                            : "text-success",
                                        ],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("vs-td", [
                                _vm._v(
                                  _vm._s(
                                    (
                                      externalAccount.balance || 0
                                    ).toLocaleString("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    })
                                  )
                                ),
                              ]),
                              _c("vs-td", [
                                _c(
                                  "div",
                                  {
                                    style: {
                                      direction: _vm.$vs.rtl ? "rtl" : "ltr",
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "Edit3Icon",
                                        svgClasses:
                                          "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editAccount(
                                            externalAccount
                                          )
                                        },
                                      },
                                    }),
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "Trash2Icon",
                                        svgClasses:
                                          "h-5 w-5 hover:text-danger cursor-pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmDeleteRecord(
                                            externalAccount
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("Conta")]),
                      _c("vs-th", [_vm._v("Tipo")]),
                      _c("vs-th", [_vm._v("Saldo")]),
                      _c("vs-th", [_vm._v("Ações")]),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("vs-divider"),
              _c("h5", [_vm._v("CONTAS INTERNAS")]),
              _c("small", [
                _c("strong", [_vm._v("Não Alteram")]),
                _vm._v(" saldo no cx geral."),
              ]),
              _c("ag-grid-vue", {
                ref: "agGridTable",
                staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                attrs: {
                  components: _vm.components,
                  gridOptions: _vm.gridOptions,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                  overlayNoRowsTemplate: _vm.noRowsTemplate,
                  localeText: _vm.localeText,
                  rowSelection: "multiple",
                  colResizeDefault: "shift",
                  animateRows: true,
                  pagination: true,
                  paginationPageSize: _vm.paginationPageSize,
                  cacheBlockSize: 20,
                  suppressPaginationPanel: true,
                  enableRtl: _vm.$vs.rtl,
                },
                on: { "grid-ready": _vm.onGridReady },
              }),
              _c("vs-pagination", {
                attrs: { total: _vm.totalPages, max: 10 },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data
    ? _c("div", { staticClass: "flex items-center" }, [
        _vm._v("\n  " + _vm._s(_vm.params.value) + "\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
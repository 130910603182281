<template>
  <div v-if="params.data" class="flex items-center">
    <feather-icon icon="CircleIcon" class="mt-4" :svgClasses="['fill-current h-4 w-4', color]" />
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    color () {
      if (this.params.data.bank_account && !this.params.data.config_boleto) {
        return 'text-primary'
      } else if (this.params.data.config_boleto) {
        return 'text-warning'
      } else {
        return 'text-success'
      }
    }
  }
}
</script>

export default {
  forSelect (state) {
    const banks = []
    for (const k in state.banks) {
      banks.push({
        value: state.banks[k].id,
        label: `${state.banks[k].bank_code} - ${state.banks[k].name}`,
        active: state.banks[k].active
      })
    }
    return banks
  }
}
